import React from 'react';
import { Link } from 'react-router-dom';

const Slider = ({ totalRadios, fmRadios, webRadios, totalChaines, totalPodcasts }) => {
    return (
        <div className="main_slider_wrapper slider-area">
            <div className="slider_side_width"></div>
            <div id="carousel-example-generic" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner" role="listbox">
                    <div className="carousel-item active">
                        <div className="carousel-captions caption-1">
                            <div className="container jn_container">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                        <div className="content">
                                            <h1 data-animation="animated fadeInUp">Bienvenue sur</h1>
                                            <h2 data-animation="animated fadeInUp">Houses of Allah</h2>
                                            <p data-animation="animated fadeInUp">Explorez une diversité de stations radio et chaînes de podcasts islamiques du Cameroun et d'ailleurs.</p>
                                            <div className="slider_btn m24_cover">
                                                <div className="lang_apply_btn">
                                                    <ul>
                                                        <li data-animation="animated fadeInUp">
                                                            <Link to="/"> <i className="flaticon-play-button"></i>Parcourir</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="clear"></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                        <div className="pricing_overview">
                                            <div className="pricing_overview_heading">
                                                <h1>STATISTIQUES</h1>
                                            </div>
                                            <div className="m24_acc_ovrview_list">
                                                <ul>
                                                    <li>RADIOS <span>{totalRadios}</span></li>
                                                    <li>CHAÎNES <span>{totalChaines}</span></li>
                                                    <li>PODCASTS <span>{totalPodcasts}</span></li>
                                                </ul>
                                                <div className="cancel_wrapper renew_btn">
                                                    <a href="#radios-section">Voir Radios</a>
                                                </div>
                                                <div className="cancel_wrapper renew_btn">
                                                    <a href="#chaines-section">Voir Chaînes</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Slider;
