import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AudioPlayerProvider } from './components/subcomponents/AudioPlayerContext';
import ScrollToTop from './components/ScrollToTop';
import Home from './components/Home';
import StationDetails from './components/StationDetails';
import ChaineDetails from './components/ChaineDetails';
import Player from './components/Player';
import Header from './components/subcomponents/Header';
import Navigation from './components/subcomponents/Navigation';
import Footer from './components/subcomponents/Footer';
import AudioPlayer from './components/subcomponents/AudioPlayer';
import PodcastDetails from './components/PodcastDetails';

function PageTitle({ title }) {
    useEffect(() => {
        document.title = title;
    }, [title]);

    return null;
}

function App() {
    return (
        <AudioPlayerProvider>
            <Router>
                <ScrollToTop />
                <Header />
                <Navigation />
                <Routes>
                    <Route path="/" element={<><PageTitle title="Accueil - Houses of Allah" /><Home /></>} />
                    <Route path="/radio/:id" element={<StationDetails />} />
                    <Route path="/chaine/:id" element={<ChaineDetails />} />
                    <Route path="/podcast/:id" element={<PodcastDetails />} />
                    <Route path="/player/:streamUrl" element={<><PageTitle title="Lecteur" /><Player /></>} />
                </Routes>
                <AudioPlayer />
                <Footer />
            </Router>
        </AudioPlayerProvider>
    );
}

export default App;
