import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

const formatNumber = (num) => {
  if (num === undefined || num === null) return '0'; // Gérer les cas où num est indéfini ou nul
  if (num >= 1000000) return (num / 1000000).toFixed(1) + 'M';
  if (num >= 1000) return (num / 1000).toFixed(1) + 'k';
  return num.toString();
};


const ChaineCard = ({ chaine }) => {
  useEffect(() => {
    $(".m24_tranding_more_icon").on("click", function(e) {
      e.preventDefault();
      e.stopImmediatePropagation();
      const container = $(this).parent();
      if (container.find("ul.tranding_more_option").hasClass("tranding_open_option")) {
        container.find("ul.tranding_more_option").removeClass("tranding_open_option");
      } else {
        $("ul.tranding_more_option.tranding_open_option").removeClass("tranding_open_option");
        container.find("ul.tranding_more_option").addClass("tranding_open_option");
      }
    });

    $(document).on("click", function() {
      $("ul.tranding_more_option.tranding_open_option").removeClass("tranding_open_option");
    });
  }, []);

  return (
    <div className="featured_artist_list m24_cover">
      <img src={chaine.image} className="img-responsive" alt={chaine.nom} />
      <div className="featured_artist_detail">
        <p><Link to={`/chaine/${chaine.id}`}>{chaine.nom}</Link></p>
        <span class="badge badge-info mt-0">{chaine.langue}</span>
        <p className="various_artist_text">
          <span>{formatNumber(chaine.podcastCount)} Podcast(s)</span> 
          &nbsp;{formatNumber(chaine.streamCount)} Stream(s)
        </p>
        <div className="lang_apply_btn">
          <ul>
            <li>
              <Link to={`/chaine/${chaine.id}`}> 
                <i className="flaticon-play-button"></i>CONSULTER
              </Link>
            </li>
          </ul>
        </div>
        <div className="top_song_list_picks featured_list_dropdown">
          <div className="m24_tranding_more_icon">
            <i className="flaticon-menu"></i>
          </div>
          <ul className="tranding_more_option">
            <li><Link to={`/chaine/${chaine.id}`}><span className="opt_icon"><i className="flaticon-playlist"></i></span>Voir Chaîne</Link></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ChaineCard;
