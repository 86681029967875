import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from './api/api';
import PodcastList from './subcomponents/PodcastList';
import SkeletonDetail from './skeletons/SkeletonDetail';

const ChaineDetails = () => {
  const { id } = useParams();
  const [chaine, setChaine] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchChaine = async () => {
      try {
        const response = await api.get(`/chaine/${id}`);
        setChaine(response.data);
        document.title = `${response.data.nom} - Houses of Allah`;
      } catch (error) {
        console.error('Erreur lors du chargement de la chaîne :', error);
      } finally {
        setLoading(false);
      }
    };

    fetchChaine();
  }, [id]);

  if (loading) {
    return <SkeletonDetail />;
  }

  return (
    <div>
      <div className="indx_title_main_wrapper" data-component="Title">
        <div className="title_img_overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
              <div className="m24_cover indx_title_left_wrapper">
                <h2>{chaine.nom}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="m24_cover artist_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-12">
              <div className="m24_cover artist_wrapper_content">
                <img alt="img" src={chaine.image} className="img-responsive" />
                <div className="artist_wrapper_text">
                  <div className="artist_wrapper_left">
                    <h1>{chaine.nom}</h1>
                    <p>{chaine.description}</p>
                    <p>{chaine.details}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="m24_cover contact_icon_section">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-6 col-md-6">
              <div className="m24_cover contact_main">
                <div className="contact_rotate"><i className="fas fa-phone"></i></div>
                <h4>Contacts</h4>
                <p>{chaine.contact || 'N/D'}</p>
              </div>
            </div>
            <div className="col-sm-12 col-lg-6 col-md-6">
              <div className="m24_cover contact_main">
                <div className="contact_rotate"><i className="fas fa-map-marker-alt"></i></div>
                <h4>Adresse</h4>
                <p>{chaine.adresse || 'N/D'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PodcastList podcasts={chaine.podcasts} chaineImage={chaine.image}  chaineId={chaine.id}/>
    </div>
  );
};

export default ChaineDetails;
