import React from 'react';
import mockupImage from '../../assets/mockup_new1.png';

const DownloadSection = () => {
    return (
        <div className="download_wrapper m24_cover">
            <div className="concert_overlay"></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="download_app_store m24_cover">
                            <h1>TÉLÉCHARGEZ L'APPLICATION</h1>
                            <p>Profitez de vos stations de radio préférées à tout moment, n'importe où.</p>
                            <div className="app_btn m24_cover">
                                <a href="#">Téléchargez l'application maintenant</a>
                            </div>
                            <ul className="download_app_logo">
                                <li><a href="#"><i className="flaticon-android-logo"></i></a></li>
                                <li><a href="#"><i className="flaticon-apple"></i></a></li>
                                <li><a href="#"><i className="flaticon-windows"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="download_mockup_design m24_cover">
                            <img src={mockupImage} className="img-responsive" alt="img" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DownloadSection;
