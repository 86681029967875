import React, { useEffect, useRef } from 'react';
import useAdvertisement from './useAdvertisement';
import api from './api/api';
import axios from 'axios';

const Advertisement = () => {
  const { ad } = useAdvertisement();
  const adRef = useRef();

  useEffect(() => {
    if (!ad) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Lorsque la publicité est visible, enregistrer une vue avec IP et localisation
            fetchAndRecordView(ad.id);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (adRef.current) {
      observer.observe(adRef.current);
    }

    return () => {
      if (adRef.current) {
        observer.unobserve(adRef.current);
      }
    };
  }, [ad]);

  const fetchAndRecordView = async (adId) => {
    try {
      const { data } = await axios.get('https://ipapi.co/json/');
      const ip = data.ip || 'Unknown IP';
      const localisation = data.city ? `${data.city}, ${data.country_name}` : 'Unknown location';

      await api.post(`/advertisement/${adId}/view`, { ip, localisation });
    } catch (error) {
      console.error("Erreur lors de l'enregistrement de la vue :", error);
    }
  };

  const fetchAndRecordClick = async (adId) => {
    try {
      const { data } = await axios.get('https://ipapi.co/json/');
      const ip = data.ip || 'Unknown IP';
      const localisation = data.city ? `${data.city}, ${data.country_name}` : 'Unknown location';

      await api.post(`/advertisement/${adId}/click`, { ip, localisation });
    } catch (error) {
      console.error("Erreur lors de l'enregistrement du clic :", error);
    }
  };

  if (!ad) {
    return null;
  }

  return (
    <div className="add_banner_wrapper m24_cover" ref={adRef}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="add_banner_img_wrapper m24_cover">
              <a
                href={ad.link}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => fetchAndRecordClick(ad.id)}
              >
                <img src={ad.image} className="img-responsive" alt={ad.name} style={{ width: '100%', height: 'auto' }} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advertisement;
