import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

const RadioCard = ({ radio }) => {
    useEffect(() => {
        $(".m24_tranding_more_icon").on("click", function(e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            const container = $(this).parent();
            if (container.find("ul.tranding_more_option").hasClass("tranding_open_option")) {
                container.find("ul.tranding_more_option").removeClass("tranding_open_option");
            } else {
                $("ul.tranding_more_option.tranding_open_option").removeClass("tranding_open_option");
                container.find("ul.tranding_more_option").addClass("tranding_open_option");
            }
        });
        
        $(document).on("click", function() {
            $("ul.tranding_more_option.tranding_open_option").removeClass("tranding_open_option");
        });
    }, []);

    if (!radio) {
        return null;
    }

    return (
        <div className="treanding_slider_main_box release_box_main_content m24_cover">
            <img src={radio.imageWeb} alt={radio.nom} className="radio-image" />
            <div className="release_content_artist">
                <p><Link to={`/radio/${radio.id}`}>{radio.nom}</Link></p>
                <p className="various_artist_text"><Link to={`/radio/${radio.id}`}>{radio.description}</Link></p>
                <span class="badge badge-info">{radio.langue}</span>
                <div className="cancel_wrapper renew_btn">
                    <Link to={`/radio/${radio.id}`}> CONSULTER</Link>
                </div>
            </div>
            <div className="m24_treanding_box_overlay release_box_overlay">
                <div className="m24_tranding_box_overlay"></div>
                <div className="m24_tranding_more_icon">
                    <i className="flaticon-menu"></i>
                </div>
                <ul className="tranding_more_option">
                    <li><Link to={`/radio/${radio.id}`}><span className="opt_icon"><i className="flaticon-playlist"></i></span>Consulter</Link></li>
                </ul>
            </div>
        </div>
    );
}

export default RadioCard;
