import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PlayButton from './PlayButton';

const PodcastList = ({ podcasts, chaineImage, chaineId }) => {
  const [visiblePodcasts, setVisiblePodcasts] = useState(3);

  if (!Array.isArray(podcasts)) {
    return null;
  }

  // Fonction pour charger 3 podcasts supplémentaires
  const loadMore = () => {
    setVisiblePodcasts((prevVisible) => prevVisible + 3);
  };

  return (
    <div className="album_inner_list m24_cover">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="m24_heading_wrapper">
              <h1>PODCASTS</h1>
            </div>
            <div className="album_list_wrapper2">
              <div className="album_list_wrapper">
                <ul className="album_list_name m24_cover">
                  <li>#</li>
                  <li className="song_title_width">Titre</li>
                  <li className="text-center">Date</li>
                  <li className="text-center">Durée</li>
                  <li className="text-center"></li>
                </ul>
                {/* Affichage des podcasts en fonction du nombre visible */}
                {podcasts.slice(0, visiblePodcasts).map((podcast, index) => (
                  <ul className="album_inner_list_padding" key={podcast.id}>
                    <li className="row_number">
                      <Link to={`#`}>
                        <span className="play_no">{index + 1}</span>
                        <span className="play_hover"><i className="flaticon-play-button"></i></span>
                      </Link>
                    </li>
                    <li className="song_title_width">
                      <div className="top_song_artist_wrapper">
                        <img src={chaineImage} alt="chaine" /> 
                        <div className="top_song_artist_contnt">
                          <h1>
                            <Link to={`/podcast/${podcast.id}`}>{podcast.titre}</Link>
                          </h1>
                          <p className="various_artist_text">
                            <Link to={`/podcast/${podcast.id}`}>{podcast.description}</Link>
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="text-center row_date">
                      <Link to={`#`}>
                        {new Date(podcast.date).toLocaleDateString('fr-FR', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                        })}
                      </Link>
                    </li>
                    <li className="text-center row_duree">
                      <Link to={`#`}>{podcast.duree}</Link>
                    </li>
                    <li className="text-center row_play">
                      <PlayButton 
                        imageUrl={chaineImage}
                        title={podcast.titre} 
                        description={podcast.description} 
                        audioUrl={podcast.streamUrl} 
                        type='chaine' 
                        id={chaineId} 
                      />
                    </li>
                  </ul>
                ))}
                {/* Bouton "Charger plus" pour afficher plus de podcasts */}
                {visiblePodcasts < podcasts.length && (
                  <div className="text-center mt-4">
                    <button className="btn btn-secondary" onClick={loadMore}>Charger plus</button>
                  </div>
                )}
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PodcastList;
