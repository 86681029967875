import { useState, useEffect } from 'react';
import api from './api/api';

const useAdvertisement = () => {
  const [ads, setAds] = useState([]);
  const [currentAdIndex, setCurrentAdIndex] = useState(0);

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const response = await api.get('/advertisements');
        setAds(response.data);
      } catch (error) {
        console.error("Erreur lors du chargement des publicités :", error);
      }
    };

    fetchAds();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentAdIndex((prevIndex) => (prevIndex + 1) % ads.length);
    }, 10000); // Change every 10 seconds

    return () => clearInterval(interval);
  }, [ads.length]);

  const ad = ads.length > 0 ? ads[currentAdIndex] : null;

  return { ad };
};

export default useAdvertisement;
