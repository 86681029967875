// PlayButton.js
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AudioPlayerContext } from './AudioPlayerContext';
import api from '../api/api';

const PlayButton = ({ imageUrl, title, description, audioUrl, type, id }) => {
    const { setAudioPlayer, stopAudio } = useContext(AudioPlayerContext);

    const handleClick = async (event) => {
        event.preventDefault();
        stopAudio();
        setAudioPlayer(imageUrl, title, description, audioUrl, type); // Passer 'type' ici

        // Incrémenter le stream en fonction du type
        try {
            if (type === 'radio') {
                await api.post(`/increment-stream/radio/${id}`);
            } else if (type === 'chaine') {
                await api.post(`/increment-stream/chaine/${id}`);
            }
            // Vous pouvez également gérer l'incrémentation pour les podcasts ici
        } catch (error) {
            console.error("Erreur lors de l'incrémentation du stream:", error);
        }
    };

    return (
        <div className="lang_apply_btn">
            <Link to="#" onClick={handleClick}>
                <i className="flaticon-play-button"></i> LIRE
            </Link>
        </div>
    );
};

export default PlayButton;
