import axios from 'axios';

// Crée une instance Axios configurée avec le token et le baseURL de l'API
const api = axios.create({
    baseURL: 'https://app.housesofallah.live/api', 
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer 6f0e62041ba978e1ce1c1f7c4b3e610488b6f6f727faf6972a9ccb06ed41894a`,
    },
});

// Interceptor de réponse pour gérer les erreurs 401
api.interceptors.response.use(
    (response) => {
        // Si la réponse est correcte, on la retourne telle quelle
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            console.error('Erreur 401: Token invalide ou expiré. Veuillez vérifier le token.');
            // Optionnel: si tu souhaites afficher un message ou rediriger l'utilisateur
            // alert("Session expirée. Veuillez vérifier vos autorisations.");
        }
        return Promise.reject(error);
    }
);

export default api;
