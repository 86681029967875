import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import api from './api/api';
import PlayButton from './subcomponents/PlayButton';
import SkeletonDetail from './skeletons/SkeletonDetail';
import axios from 'axios';

const StationDetails = () => {
  const { id } = useParams();
  const [radio, setRadio] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRadio = async () => {
      try {
        const response = await api.get(`/radio/${id}`);
        setRadio(response.data);
        document.title = `${response.data.nom} - Houses of Allah`;

        // Enregistrer la visite
        logVisit(id);
      } catch (error) {
        console.error('Erreur lors du chargement de la station radio :', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRadio();
  }, [id]);

  // Fonction pour enregistrer la visite
  const logVisit = async (radioId) => {
    try {
      // Récupérer l'IP et la localisation
      const { data } = await axios.get('https://ipapi.co/json/');
      const ip = data.ip || 'Unknown IP';
      const localisation = data.city ? `${data.city}, ${data.country_name}` : 'Unknown location';

      // Préparer les données pour l'API
      const payload = {
        ip,
        localisation,
        radio_id: radioId
      };

      // Appeler l'API pour enregistrer la visite
      await api.post('/add-visit', payload);
      console.log('Visite enregistrée avec succès');
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement de la visite :', error);
    }
  };

  if (loading) {
    return <SkeletonDetail />;
  }

  return (
    <div>
      <div className="indx_title_main_wrapper" data-component="Title">
        <div className="title_img_overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
              <div className="m24_cover indx_title_left_wrapper">
                <h2>{radio.nom}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="m24_cover artist_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-12">
              <div className="m24_cover artist_wrapper_content">
                <img alt="img" src={radio.image} className="img-responsive" />
                <div className="artist_wrapper_text">
                  <div className="artist_wrapper_left">
                    <h1>{radio.nom}</h1>
                    <p>{radio.description}</p>
                    <p>{radio.details}</p>
                    <div className="m24_cover artist_btn">
                      <PlayButton 
                          imageUrl={radio.image} 
                          title={radio.nom} 
                          description={radio.description} 
                          audioUrl={radio.streamUrl}
                          type="radio" 
                          id={radio.id}  
                        />
                      <div className="cancel_wrapper">
                          <Link to="#">
                            <i className="flaticon-radio"></i> {radio.frequence || 'N/D'}
                          </Link>
                      </div>
                      <div className="cancel_wrapper">
                          <Link to="#">
                            <i className="flaticon-globe"></i> {radio.langue}
                          </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="m24_cover contact_icon_section">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-6 col-md-6">
              <div className="m24_cover contact_main">
                <div className="contact_rotate"><i className="fas fa-phone"></i></div>
                <h4>Contacts</h4>
                <p>{radio.contact || 'N/D'}</p>
              </div>
            </div>
            <div className="col-sm-12 col-lg-6 col-md-6">
              <div className="m24_cover contact_main">
                <div className="contact_rotate"><i className="fas fa-map-marker-alt"></i></div>
                <h4>Adresse</h4>
                <p>{radio.adresse || 'N/D'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StationDetails;
