import React from 'react';

const Footer = () => {
    return (
        <>
            <div className="foter_top_wrapper m24_cover">
                <div className="container">
                    <ul>
                        <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                        <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                        <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                        <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#"><i className="fab fa-google-plus-g"></i></a></li>
                        <li><a href="#"><i className="fab fa-pinterest-p"></i></a></li>
                        <li><a href="#"><i className="fab fa-tumblr"></i></a></li>
                        <li><a href="#"><i className="fab fa-behance"></i></a></li>
                        <li><a href="#"><i className="fab fa-dribbble"></i></a></li>
                        <li><a href="#"><i className="fab fa-whatsapp"></i></a></li>
                    </ul>
                </div>
            </div>
            <div className="section2_bottom_wrapper m24_cover">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                            <div className="btm_foter_box">
                                <p>Copyright © 2024 Développé par <a href="#">BrightDigita.</a></p>
                                <div className="aboutus_social_icons">
                                    <a href="#">BrightDigita</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;
