import React, { useContext, useRef, useState, useEffect } from 'react';
import { AudioPlayerContext } from './AudioPlayerContext';

const AudioPlayer = () => {
    const { player } = useContext(AudioPlayerContext);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0); // Progress en pourcentage
    const [volume, setVolume] = useState(1); // Volume initial à 100%
    const audioRef = useRef(null);
    const playerWrapRef = useRef(null);

    useEffect(() => {
        if (audioRef.current && player.audioUrl) {
            audioRef.current.load();
            setIsPlaying(false);
            playerWrapRef.current.classList.remove('jp-state-playing');
            setIsLoading(true);

            // Appel automatique de la lecture après chargement
            audioRef.current.oncanplaythrough = () => {
                setIsLoading(false);
                handlePlay(); // Lecture automatique
            };

            // Met à jour la barre de progression si le contenu est un podcast
            if (player.type === 'chaine') {
                audioRef.current.ontimeupdate = () => {
                    const currentProgress = audioRef.current.currentTime / audioRef.current.duration;
                    setProgress(currentProgress * 100); // Convertir en pourcentage
                };
            }

            // Applique le volume initial sans arrêter la lecture
            audioRef.current.volume = volume;
        }
    }, [player]);

    useEffect(() => {
        // Applique le volume actuel lorsque le volume change sans affecter l'état de lecture
        if (audioRef.current) {
            audioRef.current.volume = volume;
        }
    }, [volume]);

    const handlePlay = () => {
        if (audioRef.current) {
            const promise = audioRef.current.play();
            if (promise !== undefined) {
                promise.then(() => {
                    setIsPlaying(true);
                    setIsLoading(false);
                    playerWrapRef.current.classList.add('jp-state-playing');
                }).catch(error => {
                    console.log(error);
                });
            }
        }
    };

    const handlePause = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            setIsPlaying(false);
            playerWrapRef.current.classList.remove('jp-state-playing');
        }
    };

    const handleProgressClick = (event) => {
        if (audioRef.current && audioRef.current.duration) {
            const rect = event.currentTarget.getBoundingClientRect();
            const clickPosition = event.clientX - rect.left;
            const clickPercentage = clickPosition / rect.width;
            const newTime = audioRef.current.duration * clickPercentage;
            audioRef.current.currentTime = newTime;
            setProgress(clickPercentage * 100);
        }
    };

    const handleVolumeClick = (event) => {
        if (audioRef.current) {
            const rect = event.currentTarget.getBoundingClientRect();
            const clickPosition = event.clientX - rect.left;
            const clickPercentage = clickPosition / rect.width;
            setVolume(clickPercentage); // Mise à jour de l'état pour afficher visuellement le volume
        }
    };

    const truncateText = (text, maxLength = 40) => {
        if (!text) return "";
        return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
    };

    return (
        <div className="adonis-player-wrap">
            <div id="adonis_jp_container" className="master-container-holder" role="application" aria-label="media player" ref={playerWrapRef}>
                <div id="adonis_jplayer_main" className="jp-jplayer"></div>
                <div className="adonis-player-horizontal">
                    <div className="master-container-fluid">
                        <div className="row adonis-player">
                            <div className="col-sm-5 col-lg-5 col-xl-5 col-5">
                                <div className="media current-item">
                                    <div className="song-poster">
                                        <img className="box-rounded-sm" src={player.imageUrl} alt="" />
                                    </div>
                                    <div className="des">
                                        <div className="jp-title" aria-label="title">{player.title}</div>
                                        <div className="artist-name"><a href="#">{truncateText(player.description, 40)}</a></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4 col-lg-4 col-xl-4 col-4 resp">
                                <div className="player-controls">
                                    <div className="control-primary">
                                        <a className="jp-previous" role="button" tabIndex="0"><span className="adonis-icon icon-2x"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 32"><path d="M55.064 0.272l-25.2 14.192c-0.555 0.299-0.925 0.876-0.925 1.54s0.371 1.241 0.916 1.535l0.009 0.005c1.336 0.784 23.64 13.344 25.256 14.216 0.265 0.162 0.585 0.258 0.928 0.258 0.986 0 1.787-0.793 1.8-1.777v-28.433c0-0.004 0-0.009 0-0.014 0-0.999-0.809-1.808-1.808-1.808-0.362 0-0.7 0.107-0.983 0.29l0.007-0.004zM26.12 0.272c-1.112 0.624-23.304 13.12-25.192 14.192-0.555 0.299-0.925 0.876-0.925 1.54s0.371 1.241 0.916 1.535l0.009 0.005c1.36 0.8 23.64 13.344 25.248 14.216 0.265 0.161 0.586 0.257 0.928 0.257 0.987 0 1.79-0.792 1.808-1.775l0-0.002v-28.432c0-0.001 0-0.003 0-0.005 0-1.003-0.813-1.816-1.816-1.816-0.362 0-0.7 0.106-0.983 0.289l0.007-0.004z"></path></svg></span></a>
                                        
                                        {isPlaying ? (
                                            <a className="jp-play" role="button" tabIndex="0" onClick={handlePause}>
                                                <span className="adonis-icon icon-pause icon-3x"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 32"><path d="M19.2 0h8c0.884 0 1.6 0.716 1.6 1.6v28.8c0 0.884-0.716 1.6-1.6 1.6h-8c-0.884 0-1.6-0.716-1.6-1.6v-28.8c0-0.884 0.716-1.6 1.6-1.6z"></path><path d="M1.6 0h8c0.884 0 1.6 0.716 1.6 1.6v28.8c0 0.884-0.716 1.6-1.6 1.6h-8c-0.884 0-1.6-0.716-1.6-1.6v-28.8c0-0.884 0.716-1.6 1.6-1.6z"></path></svg></span>
                                            </a>
                                            ) : (
                                            <a className="jp-play" role="button" tabIndex="0" onClick={handlePlay}>
                                                <span className="adonis-icon icon-play icon-3x"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 32"><path d="M27.703 14.461l-24.945-14.187c-0.272-0.174-0.604-0.278-0.96-0.278-0.993 0-1.798 0.805-1.798 1.798 0 0.001 0 0.002 0 0.004v-0 28.434c0.004 0.982 0.801 1.776 1.783 1.776 0.338 0 0.653-0.094 0.922-0.257l-0.008 0.004c1.524-0.869 23.65-13.44 25.006-14.217 0.549-0.303 0.914-0.878 0.914-1.539s-0.366-1.236-0.905-1.534l-0.009-0.005z"></path></svg></span>
                                            </a>
                                            )}
                                        <a className="jp-next" role="button" tabIndex="0"><span className="adonis-icon icon-2x"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 32"><path d="M28 14.464l-25.216-14.192c-0.276-0.179-0.614-0.286-0.976-0.286-0.999 0-1.808 0.809-1.808 1.808 0 0.005 0 0.010 0 0.015v-0.001 28.432c0.013 0.985 0.814 1.778 1.8 1.778 0.343 0 0.663-0.096 0.936-0.262l-0.008 0.005c1.6-0.872 23.896-13.432 25.256-14.216 0.559-0.298 0.934-0.877 0.934-1.544s-0.367-1.236-0.905-1.534l-0.009-0.005zM56.944 14.464l-25.216-14.192c-0.276-0.179-0.614-0.286-0.976-0.286-0.999 0-1.808 0.809-1.808 1.808 0 0.005 0 0.010 0 0.015v-0.001 28.432c0.013 0.985 0.814 1.778 1.8 1.778 0.343 0 0.663-0.096 0.936-0.262l-0.008 0.005c1.6-0.872 23.888-13.432 25.256-14.216 0.55-0.303 0.917-0.879 0.917-1.54s-0.367-1.237-0.908-1.535l-0.009-0.005z"></path></svg></span></a>
                                    </div>
                                    <audio controls src={player.audioUrl} ref={audioRef} style={{ display: 'none' }}>
                                        Your browser does not support the audio element.
                                    </audio>
                                </div>
                                <div className="text-center">{isLoading && <p>Chargement...</p>}</div>
                            </div>
                            <div className="col-sm-3 col-lg-3 col-xl-3 col-3">
                                <div className="jp_controls_wrapper">
                                    <div className="jp-volume-controls">
                                        <a className="adonis-mute-control" role="button" tabIndex="0">
                                            <span className="adonis-icon icon-volume icon-3x"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 32"><path d="M31.76 9.056l-1.36 2.592c1.265 1.020 2.071 2.567 2.080 4.302v0.002c0 1.896-0.456 3.616-1.952 4.648l1.28 2.184c1.962-1.642 3.202-4.092 3.202-6.831 0-2.776-1.272-5.254-3.266-6.884l-0.016-0.013zM36.664 4.424l-1.664 2.288c2.479 2.331 4.027 5.627 4.040 9.286v0.002c-0.027 3.717-1.634 7.053-4.182 9.375l-0.010 0.009 1.728 2.2c3.058-2.92 4.96-7.028 4.96-11.581 0-0.001 0-0.002 0-0.003v0c-0.017-4.532-1.877-8.626-4.87-11.574l-0.002-0.002zM41.6 0l-1.848 2.168c3.497 3.563 5.665 8.442 5.696 13.826l0 0.006c-0.043 5.368-2.202 10.223-5.683 13.779l0.003-0.003 1.832 2.168c3.946-4.151 6.373-9.778 6.373-15.972s-2.427-11.821-6.383-15.982l0.009 0.010zM0 10.888v10.4c0 1.328 1.2 3.016 2.688 3.016h8.080v-16.616h-8.080c-1.488 0-2.688 1.912-2.688 3.2zM23.272 0.136l-11.272 7.4v16.984l11.272 7.48c1.48 0 3.608-1.072 3.608-2.4v-27.072c0-1.32-2.128-2.392-3.608-2.392z"></path></svg></span>
                                            <span className="adonis-icon icon-mute icon-3x"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.75 11.95"><g data-name="Layer 2"><g id="Group_4" data-name="Group 4"><path d="M18.75,8.12V9.61H17.26L15.38,7.73,13.49,9.61H12V8.12l1.88-1.89L12,4.35V2.86h1.49l1.89,1.88,1.88-1.88h1.49V4.35L16.87,6.23Z"/><g id="sound_2" data-name="sound 2"><path className="cls-1" d="M0,4V7.92A1.16,1.16,0,0,0,1,9.05H4V2.83H1C.45,2.83,0,3.54,0,4ZM8.73,0,4.51,2.78V9.14L8.73,12c.55,0,1.35-.4,1.35-.9V.9C10.08.4,9.28,0,8.73,0Z"/></g></g></g></svg></span>
                                        </a>
                                        <div className="jp-volume-bar d-flex align-items-center" onClick={handleVolumeClick}>
                                            <div className="jp-volume-bar-value" style={{ width: `${volume * 100}%` }}>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="jp_current_time_wrapepr d-none d-lg-block">
                                        <div className="jp-current-time" role="timer" aria-label="time"></div>
                                        <div className="jp-duration" role="timer" aria-label="duration"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Barre de progression uniquement pour les podcasts */}
                    {player.type === 'chaine' && (
                        <div className="jp-progress d-flex align-items-center jp-progress-pos-top" onClick={handleProgressClick}>
                            <div className="jp-seek-bar" style={{ position: 'relative', width: '100%', height: '8px', backgroundColor: '#e0e0e0' }}>
                                <div className="jp-play-bar" style={{ width: `${progress}%`, height: '100%', backgroundColor: '#5a843c' }}></div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AudioPlayer;
