// src/components/skeletons/SkeletonList.js

import React from 'react';
import ContentLoader from 'react-content-loader';

const SkeletonList = () => (
  <div className="row">
    {[1, 2, 3, 4, 5, 6].map((item) => (
      <div className="col-xl-6 col-lg-6 col-md-6 mb-4" key={item}>
        <ContentLoader
          speed={2}
          width="100%"
          height={180}
          viewBox="0 0 400 160"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="160" />
        </ContentLoader>
      </div>
    ))}
  </div>
);

export default SkeletonList;
