// PodcastDetails.js
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import api from './api/api';
import PlayButton from './subcomponents/PlayButton';
import ContentLoader from 'react-content-loader';

const PodcastSkeleton = () => (
    <ContentLoader height={400} width="100%" speed={2} backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
        <rect x="0" y="10" rx="4" ry="4" width="70%" height="30" />
        <rect x="0" y="60" rx="4" ry="4" width="40%" height="20" />
        <rect x="0" y="100" rx="4" ry="4" width="100%" height="150" />
        <rect x="0" y="270" rx="4" ry="4" width="50%" height="20" />
        <rect x="0" y="310" rx="4" ry="4" width="100%" height="40" />
    </ContentLoader>
);

const PodcastDetails = () => {
    const { id } = useParams();
    const [podcast, setPodcast] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPodcast = async () => {
            try {
                const response = await api.get(`/podcast/${id}`);
                setPodcast(response.data);
            } catch (error) {
                console.error("Erreur lors du chargement du podcast :", error);
            } finally {
                setLoading(false);
            }
        };

        fetchPodcast();
    }, [id]);

    if (loading) {
        return <PodcastSkeleton />;
    }

    if (!podcast) {
        return <div>Podcast introuvable.</div>;
    }

    return (
        <div>
            {/* Title Section */}
            <div className="indx_title_main_wrapper">
                <div className="title_img_overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="indx_title_left_wrapper m24_cover">
                                <h2>{podcast.titre}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Podcast Details Section */}
            <div className="prs_es_about_main_section_wrapper m24_cover">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div className="event_single_text_wrapper">
                                <h2>Podcast #{podcast.id}</h2>
                                <h4>{podcast.titre}</h4>
                                <pre className="mt-5">{podcast.description}</pre>
                                <div className="blog-single_cntnt">
                                    <Link to={`/chaine/${podcast.chaineId}`}>{podcast.chaine}</Link>
                                </div>
                                <div className="lang_apply_btn footer_cont_btn">
                                    <ul>
                                        <li className="text-center row_play">
                                            <PlayButton 
                                                imageUrl={podcast.chaineImage}
                                                title={podcast.titre} 
                                                description={podcast.description} 
                                                audioUrl={podcast.streamUrl} 
                                                type='chaine'
                                                id={podcast.chaineId} 
                                            />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div className="pricing_overview">
                                <div className="pricing_overview_heading">
                                    <h1>DETAILS</h1>
                                </div>
                                <div className="m24_acc_ovrview_list">
                                    <ul>
                                        <li>Date : <span>{new Date(podcast.date).toLocaleDateString('fr-FR')}</span></li>
                                        <li>Durée : <span>{podcast.duree}</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PodcastDetails;
