import React, { useState } from 'react';
import RadioCard from './RadioCard';

const RadiosList = ({ nationalRadios, internationalRadios }) => {
  const langues = ['Général', 'Français', 'Anglais', 'Arabe', 'Foulfouldé', 'Haoussa', 'Autre'];
  
  // États séparés pour gérer les langues actives dans chaque section
  const [activeTabNational, setActiveTabNational] = useState('Général');
  const [activeTabInternational, setActiveTabInternational] = useState('Général');

  const renderRadios = (radios, activeTab) => {
    // Filtre les radios en fonction de la langue sélectionnée ou affiche tout si "Général" est sélectionné
    const filteredRadios = radios.filter(radio => radio.langue === activeTab || activeTab === 'Général');
    return filteredRadios.map(radio => (
      <div key={radio.id} className="col-md-3">
        <RadioCard radio={radio} />
      </div>
    ));
  };

  return (
    <div className="treanding_songs_wrapper release_wrapper m24_cover">
      <div className="container">
        {/* Section pour les radios nationales */}
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="m24_heading_wrapper">
              <h1>Stations Radios Nationales</h1>
            </div>
            <div className="release_tabs_wrapper">
              <ul className="nav nav-tabs">
                {langues.map(langue => (
                  <li key={langue} className="nav-item">
                    <a 
                      className={`nav-link ${activeTabNational === langue ? 'active' : ''}`} 
                      onClick={() => setActiveTabNational(langue)}
                      href="#!"
                    >
                      {langue}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="tab-content">
              <div className="tab-pane active">
                <div className="row">
                  {renderRadios(nationalRadios, activeTabNational)}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Section pour les radios internationales */}
        <div className="row mt-5">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="m24_heading_wrapper">
              <h1>Stations Radios Internationales</h1>
            </div>
            <div className="release_tabs_wrapper">
              <ul className="nav nav-tabs">
                {langues.map(langue => (
                  <li key={langue} className="nav-item">
                    <a 
                      className={`nav-link ${activeTabInternational === langue ? 'active' : ''}`} 
                      onClick={() => setActiveTabInternational(langue)}
                      href="#!"
                    >
                      {langue}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="tab-content">
              <div className="tab-pane active">
                <div className="row">
                  {renderRadios(internationalRadios, activeTabInternational)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RadiosList;
