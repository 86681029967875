import React, { useEffect, useState } from 'react';
import api from './api/api';
import Slider from './subcomponents/Slider';
import RadiosList from './subcomponents/RadiosList';
import ChaineList from './ChaineList';
import DownloadSection from './subcomponents/DownloadSection';
import SkeletonList from './skeletons/SkeletonList';
import Advertisement from './Advertisement';

const Home = () => {
  const [radios, setRadios] = useState([]);
  const [chaines, setChaines] = useState([]);
  const [totalPodcasts, setTotalPodcasts] = useState(0);
  const [loadingRadios, setLoadingRadios] = useState(true);
  const [loadingChaines, setLoadingChaines] = useState(true);

  useEffect(() => {
    const fetchRadios = async () => {
      try {
        const response = await api.get('/radio/');
        setRadios(response.data);
      } catch (error) {
        console.error('Erreur lors du chargement des radios :', error);
      } finally {
        setLoadingRadios(false);
      }
    };

    const fetchChaines = async () => {
      try {
        const response = await api.get('/chaine/');
        const chainesData = response.data.map((chaine) => ({
          ...chaine,
          podcastCount: chaine.podcast_count,
          streamCount: chaine.stream,
        }));
        setChaines(chainesData);

        const totalPodcastsCount = chainesData.reduce((sum, chaine) => sum + (chaine.podcastCount || 0), 0);
        setTotalPodcasts(totalPodcastsCount);
      } catch (error) {
        console.error('Erreur lors du chargement des chaînes :', error);
      } finally {
        setLoadingChaines(false);
      }
    };

    fetchRadios();
    fetchChaines();
  }, []);

  const nationalRadios = radios.filter(radio => radio.national);
  const internationalRadios = radios.filter(radio => !radio.national);

  return (
    <div>
      <Slider 
        totalRadios={radios.length} 
        fmRadios={radios.filter(radio => radio.frequence !== 'WEB RADIO').length} 
        webRadios={radios.filter(radio => radio.frequence === 'WEB RADIO').length} 
        totalChaines={chaines.length} 
        totalPodcasts={totalPodcasts} 
      />
      <div id="radios-section">
        {loadingRadios ? <SkeletonList /> : <RadiosList nationalRadios={nationalRadios} internationalRadios={internationalRadios} />}
      </div>
      <Advertisement />
      <div id="chaines-section">
        {loadingChaines ? <SkeletonList /> : <ChaineList chaines={chaines} />}
      </div>
      <DownloadSection />
    </div>
  );
};

export default Home;
