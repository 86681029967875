// src/components/skeletons/SkeletonDetail.js

import React from 'react';
import ContentLoader from 'react-content-loader';

const SkeletonDetail = () => (
  <ContentLoader
    speed={2}
    width="100%"
    height={400}
    viewBox="0 0 800 400"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="5" ry="5" width="100%" height="200" />
    <rect x="0" y="220" rx="5" ry="5" width="60%" height="30" />
    <rect x="0" y="270" rx="5" ry="5" width="80%" height="20" />
    <rect x="0" y="310" rx="5" ry="5" width="80%" height="20" />
    <rect x="0" y="350" rx="5" ry="5" width="80%" height="20" />
  </ContentLoader>
);

export default SkeletonDetail;
