import React from 'react';
import ChaineCard from './subcomponents/ChaineCard';

const ChaineList = ({ chaines }) => {
  return (
    <div className="treanding_songs_wrapper release_wrapper m24_cover">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="m24_heading_wrapper">
              <h1>Chaînes de Podcasts</h1>
            </div>
            <div className="relaese_viewall_wrapper">
              <a href="#">Voir Tout <i className="flaticon-right-arrow"></i></a>
            </div>
          </div>
          {chaines.map(chaine => (
            <div className="col-xl-6 col-lg-6 col-md-6" key={chaine.id}>
              <ChaineCard chaine={chaine} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChaineList;
