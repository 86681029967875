import React from 'react';
import { useParams } from 'react-router-dom';

function Player() {
  const { streamUrl } = useParams();

  return (
    <div>
      <audio controls>
        <source src={streamUrl} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
}

export default Player;
