import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo_houses_135.png';
import $ from 'jquery';

const Navigation = () => {
    useEffect(() => {
        $(document).ready(function() {
            // Initialisation de la navigation pour gérer les sous-menus
            $("#cssmenu li.active").addClass("open").children("ul").show();
            $("#cssmenu li.has-sub>a").on("click", function() {
                $(this).removeAttr("href");
                var t = $(this).parent("li");
                t.hasClass("open") ? function() { 
                    t.removeClass("open"); 
                    t.find("li").removeClass("open"); 
                    t.find("ul").slideUp(200); 
                }() : function() { 
                    t.addClass("open"); 
                    t.children("ul").slideDown(200); 
                    t.siblings("li").children("ul").slideUp(200); 
                    t.siblings("li").removeClass("open"); 
                    t.siblings("li").find("li").removeClass("open"); 
                    t.siblings("li").find("ul").slideUp(200); 
                }()
            });

            // Gestion du bouton de fermeture de la sidebar
            $("#toggle_close").on("click", function () {
                $("#sidebar").animate({
                    left: "-100%"
                }, "slow");
            });
        });
    }, []);

    return (
        <div id="sidebar" className="bounce-to-right open">
            <div id="toggle_close">×</div>
            <div id="cssmenu">
                <Link to="/">
                    <img src={logo} alt="Logo" />
                </Link>
                <ul className="sidebb">
                    <li><Link to="/"><i className="flaticon-home"></i>Accueil</Link></li>
                    <li className="has-sub">
                        <Link to="/"><i className="flaticon-album"></i>Radios</Link>
                        <ul>
                            <li><a href="#"><i className="flaticon-headphones"></i>Yaoundé</a></li>
                            <li><a href="#"><i className="flaticon-headphones"></i>Douala</a></li>
                            <li><a href="#"><i className="flaticon-headphones"></i>Garoua</a></li>
                            <li><a href="#"><i className="flaticon-headphones"></i>Maroua</a></li>
                        </ul>
                    </li>
                    <li className="has-sub">
                        <Link to="/"><i className="flaticon-album"></i>Chaînes</Link>
                        <ul>
                            <li><a href="#"><i className="flaticon-headphones"></i>Yaoundé</a></li>
                            <li><a href="#"><i className="flaticon-headphones"></i>Douala</a></li>
                            <li><a href="#"><i className="flaticon-headphones"></i>Garoua</a></li>
                            <li><a href="#"><i className="flaticon-headphones"></i>Maroua</a></li>
                        </ul>
                    </li>
                    <li><a href="#"><i className="flaticon-internet"></i>Contact us</a></li>
                </ul>
            </div>
        </div>
    );
};

export default Navigation;
