import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo_houses_135.png';
import $ from 'jquery';
import api from '../api/api';

const Header = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState({ radios: [], chaines: [], podcasts: [] });
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const searchRef = useRef(null);

    useEffect(() => {
        // Ouvre la sidebar avec l'effet d'animation
        $("#toggle").on("click", function () {
            $("#sidebar").animate({
                left: 0
            }, "slow");
        });
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setIsDropdownVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleSearchChange = async (event) => {
        const term = event.target.value;
        setSearchTerm(term);

        if (term.length > 2) {
            setIsDropdownVisible(true);
            try {
                const radiosResponse = await api.get(`/radio/search?query=${term}`);
                const chainesResponse = await api.get(`/chaine/search?query=${term}`);
                const podcastsResponse = await api.get(`/podcast/search?query=${term}`);

                setSearchResults({
                    radios: radiosResponse.data,
                    chaines: chainesResponse.data,
                    podcasts: podcastsResponse.data
                });
            } catch (error) {
                console.error("Erreur lors de la recherche :", error);
            }
        } else {
            setSearchResults({ radios: [], chaines: [], podcasts: [] });
            setIsDropdownVisible(false);
        }
    };

    return (
        <div className="m24_navi_main_wrapper m24_cover">
            <div className="container-fluid">
                <div className="m24_logo_wrapper">
                    <div className="m24_logo_div">
                        <Link to="/">
                            <img src={logo} alt="Logo" />
                        </Link>
                    </div>
                    <div id="toggle">
                        <Link to="#" onClick={(e) => e.preventDefault()}>
                            <i className="flaticon-menu-1"></i>
                        </Link>
                    </div>
                </div>

                <div className="m24_navigation_wrapper" ref={searchRef}>
                    <div className="navi_searchbar_wrapper">
                        <i className="flaticon-magnifying-glass"></i>
                        <input
                            type="text"
                            placeholder="Rechercher une station radio ou Chaîne ..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                            onFocus={() => searchTerm.length > 2 && setIsDropdownVisible(true)}
                        />
                        {isDropdownVisible && (
                            <div className="search-results-dropdown">
                                <div className="search-section">
                                    <h4>Radios</h4>
                                    {searchResults.radios.length > 0 ? (
                                        searchResults.radios.map((radio) => (
                                            <Link key={radio.id} to={`/radio/${radio.id}`} onClick={() => setIsDropdownVisible(false)}>
                                                {radio.nom}
                                            </Link>
                                        ))
                                    ) : (
                                        <p>Aucun résultat pour les radios</p>
                                    )}
                                </div>
                                <div className="search-section">
                                    <h4>Chaînes</h4>
                                    {searchResults.chaines.length > 0 ? (
                                        searchResults.chaines.map((chaine) => (
                                            <Link key={chaine.id} to={`/chaine/${chaine.id}`} onClick={() => setIsDropdownVisible(false)}>
                                                {chaine.nom}
                                            </Link>
                                        ))
                                    ) : (
                                        <p>Aucun résultat pour les chaînes</p>
                                    )}
                                </div>
                                <div className="search-section">
                                    <h4>Podcasts</h4>
                                    {searchResults.podcasts.length > 0 ? (
                                        searchResults.podcasts.map((podcast) => (
                                            <Link key={podcast.id} to={`/podcast/${podcast.id}`} onClick={() => setIsDropdownVisible(false)}>
                                                {podcast.titre}
                                            </Link>
                                        ))
                                    ) : (
                                        <p>Aucun résultat pour les podcasts</p>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="m24_navi_langauage_box">
                        <div className="theme-switch-wrapper">
                            <label className="theme-switch" htmlFor="checkbox">
                                <input type="checkbox" id="checkbox" />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        <div className="lang_list_wrapper d-none d-sm-none d-md-none d-lg-none d-xl-block">
                            <Link to="#" onClick={(e) => e.preventDefault()} data-toggle="modal" data-target="#myModal">
                                langues <i className="fas fa-language"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
