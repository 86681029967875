// AudioPlayerContext.js
import React, { createContext, useState } from 'react';

export const AudioPlayerContext = createContext();

export const AudioPlayerProvider = ({ children }) => {
  const [player, setPlayer] = useState({});

  const setAudioPlayer = (imageUrl, title, description, audioUrl, type) => {
    setPlayer({ imageUrl, title, description, audioUrl, type });
  };

  const stopAudio = () => {
    setPlayer({});
  };

  return (
    <AudioPlayerContext.Provider value={{ player, setAudioPlayer, stopAudio }}>
      {children}
    </AudioPlayerContext.Provider>
  );
};
